<template>
  <div class="number d-flex">
    <span class="minus" @click="minus" :class="{'disabled': modelValue===min}"><i class="fa-solid fa-minus"></i></span>
    <input type="text" class="form-control" :value="modelValue" readonly/>
    <span class="plus" @click="plus" :class="{'disabled': modelValue===max}"><i class="fa-solid fa-plus"></i></span>
  </div>
</template>

<script>
export default {
  name: 'spin-plus-minus',
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 5,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  methods: {
    minus() {
      let v = this.modelValue;
      if (v > this.min) v -= 1;
      this.$emit('change', v);
    },
    plus() {
      let v = this.modelValue;
      if (v < this.max) v += 1;
      this.$emit('change', v);
    },

  },
};
</script>
<style scoped>
.compositiondropdown_box .number .minus:not(.disabled) {
  background: #0263ac;
}
.compositiondropdown_box .number .minus.disabled, .compositiondropdown_box .number .plus.disabled{
  background: #b8b8b8;
  cursor: no-drop;
}
.number input {
  background-color: transparent;
}
</style>
